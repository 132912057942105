import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { RestaurantInformation } from "components/RestaurantInformation";
import { RestaurantLocation } from "components/RestaurantLocation";
import { ReviewsWidget } from "components/ReviewsWidget";
import { RestaurantActionBar } from "components/RestaurantActionBar";
import { RestaurantHeroSection } from "components/RestaurantHeroSection/RestaurantHeroSection";
import { SimilarRestaurants } from "components/SimilarRestaurants";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";

import "./restaurant.scss";

export const Restaurant = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useParams(); //get dynamic selected id

  useEffect(() => {
    // get Restaurants data
    fetch(
      `http://frontendsourceryweb.s3-website.eu-central-1.amazonaws.com/restaurants.json`
    )
      .then((response) => response.json())
      .then((responseData) => {
        setRestaurants(responseData.restaurants);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setRestaurant(restaurants.find((item) => item.id === id)); //assign restaurant object
  }, [restaurants, id]);

  return (
    <>
      {isLoading && <h2 className="alt">Restaurants are loading...</h2>}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {restaurant && (
        <div key={restaurant.id}>
          <RestaurantHeroSection
            title={restaurant.name}
            image={restaurant.image}
            categories={restaurant.categories}
          />
          <section className="restaurant">
            <RestaurantActionBar restaurant={restaurant} />
            <RestaurantInformation restaurant={restaurant} />
            <RestaurantLocation
              mapZoomLevel={14}
              coordinates={restaurant.location.coordinates}
            />
            <h2 className="restaurant__reviews alt">Reviews</h2>
            <ReviewsWidget reviews={restaurant.reviews} />
            <SimilarRestaurants
              criteria={restaurant}
              restaurants={restaurants}
            />
          </section>
        </div>
      )}
    </>
  );
};
export default Restaurant;
