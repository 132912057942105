import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { NavbarLink } from "components/NavbarLink/NavbarLink";

import "./navigation.scss";

import dashboard from "../../assets/navigation/dashboard.svg";
import eatOut from "../../assets/navigation/eatOut.svg";
import reservations from "../../assets/navigation/reservations.svg";
import { ReactComponent as IconMenu } from "../../assets/icons/menu.svg";

export const Navigation = ({ arrOfNavLinks }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const links = arrOfNavLinks
    ? arrOfNavLinks
    : [
        { text: "Dashboard", img: dashboard, path: "/dashboard" },
        { text: "Reservations", img: reservations, path: "/reservations" },
        { text: "Eat-Out", img: eatOut, path: "/eat-out" },
      ];
  const hamburgerMenu = useRef();
  const hamburgerMenuIcon = useRef();
  useEffect(() => {
    const closeIfClickedOutside = (e) => {
      if (
        isMobileMenuOpen &&
        hamburgerMenuIcon.current &&
        !hamburgerMenuIcon.current.contains(e.target) &&
        !hamburgerMenu.current.contains(e.target)
      ) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", closeIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", closeIfClickedOutside);
    };
  }, [isMobileMenuOpen]);
  return (
    <nav className="navigation">
      <button
        onClick={() => setIsMobileMenuOpen((previousValue) => !previousValue)}
        className="navigation__toggle-mobile-menu"
        ref={hamburgerMenuIcon}
      >
        <IconMenu />
      </button>

      <ul
        className={classNames(
          "navigation__list",
          isMobileMenuOpen && "navigation__list--open-on-mobile"
        )}
        ref={hamburgerMenu}
      >
        {links &&
          links.map((item, index) => (
            <li className="navigation__element" key={index}>
              <NavbarLink
                text={item.text}
                img={item.img}
                path={item.path}
                action={() => setIsMobileMenuOpen(false)}
              />
            </li>
          ))}
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  arrOfNavLinks: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      img: PropTypes.node,
      path: PropTypes.string,
    })
  ),
};
