export const putCursorAtEnd = (event) => {
  const valueSelector = event.target;
  const currentValue = valueSelector.value;

  if (currentValue.length > 0) {
    valueSelector.selectionStart = valueSelector.selectionEnd =
      currentValue.length;
    valueSelector.focus();
  }
};
