import React from "react";

import travolta from "../assets/not-found-404/7VE.gif";

import { Link } from "react-router-dom";

import "./not-found.scss";

const NotFound404 = () => {
  return (
    <section className="not-found">
      <h1 className="not-found__title">Error 404: Page Not Found!</h1>
      <div className="not-found__container">
        <Link to="/dashboard" className="not-found__link">
          Back to Dashboard
        </Link>
        <img className="not-found__image" src={travolta} alt="background" />
      </div>
    </section>
  );
};

export default NotFound404;
