import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as IconGiftMonochrome } from "../../assets/icons/icon-gift-monochrome.svg";
import { ReactComponent as IconGiftColored } from "../../assets/icons/icon-gift-colored.svg";
import { ReactComponent as IconHeartMonochrome } from "../../assets/icons/icon-heart-monochrome.svg";
import { ReactComponent as IconHeartColored } from "../../assets/icons/icon-heart-colored.svg";
import { ReactComponent as IconComment } from "../../assets/icons/icon-comment-monochrome.svg";
import "./social-button.scss";

export const SocialButton = ({
  iconType,
  isIconClicked,
  handleIconToggle,
  commentInputToggler,
}) => {
  let ButtonIcon, label;

  switch (iconType) {
    case "birthday": {
      ButtonIcon = isIconClicked ? IconGiftColored : IconGiftMonochrome;
      label = "gift-toggle";
      break;
    }
    case "comment": {
      ButtonIcon = IconComment;
      label = "comment-field-toggle";
      break;
    }
    default:
      ButtonIcon = isIconClicked ? IconHeartColored : IconHeartMonochrome;
      label = "like-toggle";
      break;
  }

  ButtonIcon = <ButtonIcon className="social-button__icon" />;

  return (
    <button
      type="button"
      className="social-button"
      aria-label={label}
      aria-pressed={isIconClicked}
      onClick={handleIconToggle || commentInputToggler}
    >
      {ButtonIcon}
    </button>
  );
};

SocialButton.propTypes = {
  iconType: PropTypes.string,
  handleIconToggle: PropTypes.func,
  isIconClicked: PropTypes.bool,
  commentInputToggler: PropTypes.func,
};
