import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import "./hero-slider.scss";
import { Dots } from "./Dots";
import { Slide } from "./Slide";
import { SliderControls } from "components/SliderControls/SliderControls";

export const HeroSlider = ({ restaurants }) => {
  const [visibleSlide, setVisibleSlide] = useState(1);
  const [slides, setSlides] = useState([]);
  const [transition, setTransition] = useState(false);
  const [isArrowDisabled, setIsArrowDisabled] = useState(false);

  const sliderWidth = 100 * slides.length;
  const slideWidth = sliderWidth / slides.length / slides.length;
  const transitionTime = 500; //don't forget to change .transition in hero-slider.scss when changing this property
  const autoSliderTimer = 10000;

  const activeDot = (index) => {
    return (
      index === visibleSlide ||
      (index === 1 && visibleSlide === slides.length - 1) ||
      (index === slides.length - 2 && visibleSlide === 0)
    );
  };
  const nextSlide = useCallback(() => {
    setVisibleSlide(visibleSlide + 1);
  }, [setVisibleSlide, visibleSlide]);

  const prevSlide = () => {
    setVisibleSlide(visibleSlide - 1);
  };

  const translateToSide = () => {
    return `-${visibleSlide * slideWidth}%`;
  };

  useEffect(() => {
    const slideArray = [...restaurants.slice(0, 5)];
    slideArray.unshift(slideArray[slideArray.length - 1]);
    slideArray.push(slideArray[1]);
    setSlides(slideArray);

    if (visibleSlide === slides.length - 1) {
      setIsArrowDisabled(true);
      setTimeout(() => {
        setTransition(false);
        setVisibleSlide(1);
      }, transitionTime);
    }

    if (visibleSlide === 1) {
      setTimeout(() => {
        setTransition(true);
      }, transitionTime);
    }

    if (visibleSlide === 0) {
      setIsArrowDisabled(true);
      setTimeout(() => {
        setTransition(false);
        setVisibleSlide(slides.length - 2);
      }, transitionTime);
    }

    if (visibleSlide === slides.length - 2) {
      setTimeout(() => {
        setTransition(true);
      }, transitionTime);
    }

    if (isArrowDisabled) {
      setTimeout(() => {
        setIsArrowDisabled(false);
      }, transitionTime * 2);
    }
  }, [visibleSlide, slides.length, restaurants, isArrowDisabled]);

  useEffect(() => {
    const autoNextSlide = setInterval(() => {
      nextSlide();
    }, autoSliderTimer);

    return () => {
      clearTimeout(autoNextSlide);
    };
  }, [nextSlide]);

  return (
    <div className="slider">
      <div className="slider__controls">
        <Dots
          slides={slides}
          setVisibleSlide={setVisibleSlide}
          activeDot={activeDot}
        />
        <div className="slider__arrows">
          <div className="controls">
            <SliderControls
              direction="prev"
              prevSlide={prevSlide}
              isArrowDisabled={isArrowDisabled}
            ></SliderControls>
            <SliderControls
              direction="next"
              nextSlide={nextSlide}
              isArrowDisabled={isArrowDisabled}
            ></SliderControls>
          </div>
        </div>
      </div>
      <div
        className={`slider__content ${transition ? "transition" : ""} `}
        style={{
          width: `${sliderWidth}%`,
          transform: `translateX(${translateToSide()})`,
        }}
      >
        <Slide slides={slides} slideWidth={slideWidth}></Slide>
      </div>
    </div>
  );
};

HeroSlider.propTypes = {
  restaurants: PropTypes.array,
};
