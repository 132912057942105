import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LogoMonochrome } from "../../assets/logo-icon-monochrome.svg";
import { ReactComponent as LogoColored } from "../../assets/logo-icon-colored.svg";

export const Logo = ({ isMonochrome = true }) => {
  return <>{isMonochrome ? <LogoMonochrome /> : <LogoColored />}</>;
};

Logo.propTypes = {
  isMonochrome: PropTypes.bool,
};
