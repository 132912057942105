import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./reservation-card.scss";

import { ReactComponent as Book } from "../../assets/reservation-card/book.svg";
import { ReactComponent as Door } from "../../assets/reservation-card/door.svg";
import { ReactComponent as Phone } from "../../assets/reservation-card/phone.svg";
import { ReactComponent as Other } from "../../assets/reservation-card/hand-truck.svg";

export const ReservationCard = ({ type, reservedQty }) => {
  let title, ReservationCardIcon;

  switch (type) {
    case "books":
      title = "Books";
      ReservationCardIcon = <Book />;
      break;
    case "rooms":
      title = "Meeting rooms";
      ReservationCardIcon = <Door />;
      break;
    case "devices":
      title = "Devices";
      ReservationCardIcon = <Phone />;
      break;
    default:
      title = "Other";
      ReservationCardIcon = <Other />;
  }

  return (
    // prevent link prior to Reservations page implementation
    <Link className="reservation-card" to="">
      <div className="reservation-card__content">
        <h2 className="reservation-card__title alt">{title}</h2>
        {reservedQty && (
          <div className="reservation-card__quantity caption-two">
            {reservedQty} reserved
          </div>
        )}
      </div>
      <div className="reservation-card__decoration">{ReservationCardIcon}</div>
    </Link>
  );
};

ReservationCard.propTypes = {
  type: PropTypes.string.isRequired,
  reservedQty: PropTypes.number,
};
