import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { RatingCard } from "components/RatingCard/RatingCard";
import { CheckInCard } from "components/CheckInCard/CheckInCard";
import { SocialButton } from "components/SocialButton/SocialButton";
import { RestaurantCardAddon } from "components/RestaurantCardAddon";

import { getWeekdays } from "utils/getWeekdays";

import "./restaurant-card.scss";

import { UserData } from "context/UserData";

export const RestaurantCard = ({
  id,
  openingHours,
  categories,
  reviews,
  name,
  image,
  checkIns,
  website,
  address,
  expand,
  coordinates,
  description,
}) => {
  const userData = useContext(UserData);

  const [isLiked, setIsLiked] = useState(
    userData?.data?.liked?.restaurants.some(
      (restaurant) => restaurant.id === id
    )
  );
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (userData) {
      if (userData?.data?.checkIn?.restaurants?.length > 0) {
        setIsChecked(
          userData?.data?.checkIn?.restaurants?.some(
            (restaurantId) => restaurantId === id
          )
        );
      }
    }
  }, [userData, id]);

  const handleLikedToggle = () => {
    setIsLiked(!isLiked);
  };

  const checkMeIn = () => {
    setIsChecked(!isChecked);
  };

  if (!name) return null;

  const isOpened = getWeekdays(openingHours);

  return (
    <div className="restaurant-card">
      <div className="restaurant-card__bg">
        {image && <img src={image} alt="restaurant cover" />}
      </div>
      <div className="restaurant-card__top-section">
        <CheckInCard checkIns={checkIns} isChecked={isChecked} />
        <RatingCard reviews={reviews} />
      </div>
      <div
        className={`restaurant-card__bottom-section ${
          expand && `restaurant-card__bottom-section--expanded`
        }`}
      >
        <div className="restaurant-card__data">
          {categories && (
            <div className="restaurant-card__categories tiny">
              {categories.map((category) => (
                <Link
                  className="restaurant-card__category "
                  key={category}
                  to={`/eat-out/${category}`}
                >
                  {category}
                </Link>
              ))}
            </div>
          )}
          <Link
            className="restaurant-card__title"
            to={`/eat-out/restaurants/${id}`}
            state={{ name: name }}
          >
            <h3>{name}</h3>
          </Link>
          <p className="restaurant-card__time tiny">
            <span className="restaurant-card__time tiny">{isOpened}</span>
          </p>
        </div>
        <SocialButton
          isIconClicked={isLiked}
          handleIconToggle={handleLikedToggle}
        />
      </div>
      {expand && (
        <RestaurantCardAddon
          website={website}
          address={address}
          id={id}
          name={name}
          coordinates={coordinates}
          description={description}
          isChecked={isChecked}
          checkMeIn={checkMeIn}
        />
      )}
    </div>
  );
};

RestaurantCard.propTypes = {
  id: PropTypes.string.isRequired,
  openingHours: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  reviews: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  checkIns: PropTypes.number.isRequired,
  website: PropTypes.string,
  address: PropTypes.string,
  description: PropTypes.string,
  coordinates: PropTypes.object,
  expand: PropTypes.bool,
};
