import React, { useContext, useState, useEffect } from "react";
import Masonry from "react-masonry-css";

import { BdayCard } from "components/BdayCard";
import { PostCard } from "components/PostCard";

import "./newsfeed.scss";

import { UserData } from "context";

export const Newsfeed = () => {
  const userData = useContext(UserData);

  const [stories, setStories] = useState([]);

  useEffect(() => {
    // get stories data
    fetch(
      `http://frontendsourceryweb.s3-website.eu-central-1.amazonaws.com/stories.json`
    )
      .then((response) => response.json())
      .then((data) => setStories(data.stories.reverse()))
      .catch((error) => window.alert("ERORR: couldn't load stories data"));
  }, []);

  const breakpoints = {
    default: 4,
    1860: 3,
    1407: 2,
    954: 1,
  };

  return (
    <section className="newsfeed">
      <h2 className="newsfeed__header alt">News and Stories</h2>
      <Masonry breakpointCols={breakpoints} className="newsfeed__layout">
        {!userData.isLoading &&
          stories?.map((story, key) => {
            if (story.type === "birthday") {
              return (
                <article className="newsfeed__item" key={key}>
                  <BdayCard
                    name={story.userName}
                    birthDate={new Date(story.birthdayDate)}
                    userImage={story.userImage}
                    currentUserName={userData.data.userName}
                    currentUserImage={userData.data.userImage}
                    wishes={story.wishes}
                    comments={story.comments}
                  />
                </article>
              );
            } else {
              return (
                <article className="newsfeed__item" key={key}>
                  <PostCard
                    name={story.userName}
                    userImage={story.userImage}
                    currentUserName={userData.data.userName}
                    currentUserImage={userData.data.userImage}
                    imgLink={
                      story.type === "post" ? story.postImage : story.postVideo
                    }
                    imgType={story.type}
                    date={story.postDate}
                    location={story.postLocation}
                    likes={story.likes}
                    comments={story.comments}
                  />
                </article>
              );
            }
          })}
      </Masonry>
    </section>
  );
};
