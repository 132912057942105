import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./form-field.scss";

export const FormField = ({
  labelName,
  placeholder = labelName,
  type,
  value,
  onChange,
  onBlur,
  isInvalid,
  errorMessage,
  isDisabled,
}) => {
  const id = labelName.toLowerCase().replace(" ", "-");

  const labelClassName = classNames("form-field__label", {
    "form-field__label--disabled": isDisabled,
  });

  const inputClassName = classNames("form-field__input", {
    "form-field__input--error": isInvalid,
  });

  return (
    <div className="form-field">
      <label htmlFor={id} className={labelClassName}>
        {labelName}
      </label>
      <input
        type={type}
        id={id}
        placeholder={placeholder}
        className={inputClassName}
        disabled={isDisabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {isInvalid && (
        <div className="tiny form-field__error">{errorMessage}</div>
      )}
    </div>
  );
};

FormField.propTypes = {
  labelName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isInvalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
};
