import React from "react";

import { Newsfeed } from "components/Newsfeed";
import { WeatherWidget } from "components/WeatherWidget/WeatherWidget";
import { HelloWidget } from "components/HelloWidget";
import { ReservationsWidget } from "components/ReservationsWidget";
import { RestaurantsWidget } from "components/RestaurantsWidget/RestaurantsWidget";

import "./dashboard.scss";

const Dashboard = () => {
  return (
    <div className="dashboard">
      <HelloWidget />
      <WeatherWidget />
      <ReservationsWidget />
      <RestaurantsWidget />
      <Newsfeed />
    </div>
  );
};

export default Dashboard;
