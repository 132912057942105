import React from "react";
import PropTypes from "prop-types";
import { Slider } from "components/Slider";
import { useSortByLocation } from "hooks/useSortByLocation";
import "./nearest-restaurants.scss";

export const NearestRestaurants = ({ restaurants }) => {
  const modifiedArray = useSortByLocation(restaurants);

  if (modifiedArray?.error) {
    return (
      <div className="nearest-restaurants">
        <h2 className="nearest-restaurants__heading alt">Discover near you</h2>
        <p className="nearest-restaurants__info">
          If you want to see restaurants near you, you need to allow &apos;Team
          Space&apos; App to access your location. To do this, click on i icon
          in your browser&apos;s address bar left from the url and enable
          locations permissions. After that, refresh page.
        </p>
      </div>
    );
  }

  return (
    <Slider
      heading={"Discover near you"}
      modifiedArray={modifiedArray?.data}
    ></Slider>
  );
};

NearestRestaurants.propTypes = {
  restaurants: PropTypes.array,
};
