import React, { useContext } from "react";
import PropTypes from "prop-types";

import "./reservations-widget.scss";

import { ReservationCard } from "components/ReservationCard";

import { UserData } from "context/UserData";

export const ReservationsWidget = ({ hideQty }) => {
  const userData = useContext(UserData);
  const allReservations = userData.data.reservations;

  return (
    <div className="reservations-widget">
      {!hideQty && (
        <h2 className="reservations-widget__title alt">Reservations</h2>
      )}
      {!userData.isLoading && (
        <div className="reservations-widget__container">
          <ReservationCard
            type="rooms"
            reservedQty={!hideQty ? allReservations.rooms.length : null}
          />
          <ReservationCard
            type="books"
            reservedQty={!hideQty ? allReservations.books.length : null}
          />
          <ReservationCard
            type="devices"
            reservedQty={!hideQty ? allReservations.devices.length : null}
          />
        </div>
      )}
    </div>
  );
};

ReservationsWidget.propTypes = {
  hideQty: PropTypes.bool,
};
