export const postAgeCalculator = (date) => {
  let createdAt = new Date(date);
  let dateNow = new Date();
  let differenceBetweenDates = Math.abs(dateNow - createdAt);
  let differenceInHours = Math.floor(differenceBetweenDates / (1000 * 60 * 60));
  if (differenceInHours > 24) {
    let differenceInDays = Math.floor(
      differenceBetweenDates / (1000 * 60 * 60 * 24)
    );
    if (differenceInDays > 7) {
      let differenceInWeeks = Math.floor(
        differenceBetweenDates / (1000 * 60 * 60 * 24 * 7)
      );
      if (differenceInWeeks > 4) {
        let differenceInMonth = Math.floor(
          differenceBetweenDates / (1000 * 60 * 60 * 24 * 30)
        );
        if (differenceInMonth > 11) {
          let differenceInYears = Math.floor(
            differenceBetweenDates / (1000 * 60 * 60 * 24 * 7 * 52)
          );
          return `${differenceInYears}Y`;
        } else {
          return `${differenceInMonth}M`;
        }
      } else {
        return `${differenceInWeeks}W`;
      }
    } else {
      return `${differenceInDays}D`;
    }
  } else {
    return `${differenceInHours}H`;
  }
};
