import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "components/Button/Button";
import { ReactComponent as Globe } from "../../assets/restaurant-information/globe.svg";
import { ReactComponent as MapPin } from "../../assets/restaurant-information/map-pin.svg";

import "./restaurant-card-addon.scss";

export const RestaurantCardAddon = ({
  website,
  address,
  id,
  name,
  coordinates,
  description,
  isChecked,
  checkMeIn,
}) => {
  const slicedWebAddress = website
    .replace("http://", " ")
    .replace("https://", " ")
    .replace("www.", " ");

  const btnTitle = `check-${isChecked ? "out" : "in"}`;

  return (
    <div className="addon">
      <div className="addon__link-box caption-two">
        <a
          href={`${website}`}
          target="_blank"
          rel="noopener noreferrer"
          className="addon__link"
        >
          <Globe />
          <div className="addon__webpage-wrap">
            <span className="addon__web-page">{slicedWebAddress}</span>
          </div>
        </a>

        <a
          href={`http://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}`}
          className="addon__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MapPin />
          <div className="addon__address-wrap">
            <span className="addon__address">{address}</span>
          </div>
        </a>
      </div>
      <div className="addon__description">{description}</div>
      <div className="addon__actions">
        <Link
          to={`/eat-out/restaurants/${id}`}
          className="addon__restaurant-page caption-two"
          state={{ name: name }}
        >
          Read More
        </Link>
        <Button title={btnTitle} type="button" size="medium" func={checkMeIn} />
      </div>
    </div>
  );
};

RestaurantCardAddon.propTypes = {
  website: PropTypes.string,
  address: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  coordinates: PropTypes.object,
  isChecked: PropTypes.bool,
  checkMeIn: PropTypes.func,
};
