import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./navbar-link.scss";

export const NavbarLink = ({ text, img, path, action }) => {
  return (
    <NavLink to={path} onClick={action}>
      <img src={img} alt={text} />
      <span>{text}</span>
    </NavLink>
  );
};

NavbarLink.propTypes = {
  img: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  action: PropTypes.func,
};
