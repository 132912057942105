import React, { useState, useEffect, useContext } from "react";
import "./hello-widget.scss";
import { UserData } from "context/UserData";

export const HelloWidget = () => {
  const userData = useContext(UserData);
  const user = userData.data.userName;
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const time = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearTimeout(time);
    };
  }, []);

  const addZero = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const hours = addZero(date.getHours());
  const minutes = addZero(date.getMinutes());
  const daytime =
    (hours < 12 && "morning") || (hours < 18 && "afternoon") || "evening";
  let greetingsContent;

  if (user) {
    greetingsContent = (
      <h1 className="hello-widget__greetings">
        Good {daytime}, <span className="hello-widget__user">{user}!</span>
      </h1>
    );
  }

  if (!user) {
    greetingsContent = <h1 className="hello-widget__greetings">Loading...</h1>;
  }

  return (
    <div className="hello-widget">
      <div className="hello-widget__time">{`${hours}:${minutes}`}</div>
      {greetingsContent}
    </div>
  );
};
