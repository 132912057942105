import React, { useState } from "react";
import { ReactComponent as ErrorIcon } from "../../assets/icons/icon-error.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon-close.svg";
import PropTypes from "prop-types";
import "./error-message.scss";

export const ErrorMessage = ({ errorMessage }) => {
  const [isErrMsgShown, setIsErrMsgShown] = useState(true);

  const handleErrMsgClose = () => setIsErrMsgShown(false);

  return (
    isErrMsgShown && (
      <div className="error-message">
        <ErrorIcon />
        <p className="error-message__text">{`Error. ${errorMessage}. Try visit later or check other pages.`}</p>
        <CloseIcon
          className="error-message__close"
          onClick={handleErrMsgClose}
        />
      </div>
    )
  );
};

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
};
