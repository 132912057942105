import React from "react";
import PropTypes from "prop-types";
import "./check-in-card.scss";
import { ReactComponent as CheckInIcon } from "./../../assets/icons/icon-check-in-colored.svg";

export const CheckInCard = ({ checkIns, isChecked }) => {
  if (checkIns == null) return null;

  return (
    <div className="check-in-card">
      <span className="check-in-card__icon caption">
        <CheckInIcon />
      </span>
      <span className="check-in-card__value caption">
        {checkIns + isChecked}
      </span>
    </div>
  );
};

CheckInCard.propTypes = {
  checkIns: PropTypes.number.isRequired,
  isChecked: PropTypes.bool,
};
