import React from "react";
import PropTypes from "prop-types";
import "./comment.scss";

export const Comment = (props) => {
  const { date, userName, comment } = props.commentData;
  const { getFormattedDate } = props;
  const dateLocaleString = new Date(date).toLocaleString();
  const formatedDate = getFormattedDate(dateLocaleString);

  return (
    <li className="comment">
      <header className="comment__header tiny">
        <div className="comment__author">{userName}</div>
        <div className="comment__date">{formatedDate}</div>
      </header>
      <p className="comment__text">{comment}</p>
    </li>
  );
};

Comment.propTypes = {
  commentData: PropTypes.object,
  getFormattedDate: PropTypes.func,
};
