import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";

import "./register.scss";

import { FormContainer } from "components/FormContainer";
import { FormField } from "components/FormField/FormField";

import { useAuth } from "hooks/useAuth";

import { fieldTemplate, validateField } from "utils/validation";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  // saving caller-route in case we switch to /login
  const origin = location.state?.from;
  const backToCaller = origin?.pathname || "/";

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formIsValid || !formWasChanged) return; // no API calls before the form is valid

    const messageBody = {
      userName: firstName + " " + lastName,
      email: email,
      password: password,
    };

    login(messageBody);

    // after successful login, redirect back to the page that called the login
    // and clear the last item in the  browse history
    navigate(backToCaller, { replace: true });
  };

  const [firstName, setFirstName] = useState({ ...fieldTemplate });
  const [lastName, setLastName] = useState({ ...fieldTemplate });
  const [email, setEmail] = useState({ ...fieldTemplate });
  const [password, setPassword] = useState({ ...fieldTemplate });
  const [repeatPassword, setRepeatPassword] = useState({ ...fieldTemplate });
  const [formIsValid, setFormIsValid] = useState(false);
  const [formWasChanged, setFormWasChanged] = useState(false);

  const delayedValidateField = useMemo(() => debounce(validateField, 500), []);

  const updateFirstName = (event) => {
    setFirstName((prev) => {
      return { ...prev, value: event.target.value };
    });
    delayedValidateField(event, setFirstName);
  };

  const updateLastName = (event) => {
    setLastName((prev) => {
      return { ...prev, value: event.target.value };
    });
    delayedValidateField(event, setLastName);
  };

  const updateEmail = (event) => {
    setEmail((prev) => {
      return { ...prev, value: event.target.value };
    });
    delayedValidateField(event, setEmail);
  };

  const updatePassword = (event) => {
    setPassword((prev) => {
      return { ...prev, value: event.target.value };
    });
    delayedValidateField(event, setPassword, { match: repeatPassword.value });
  };

  const updateRepeatPassword = (event) => {
    setRepeatPassword((prev) => {
      return { ...prev, value: event.target.value };
    });
    delayedValidateField(event, setRepeatPassword, { match: password.value });
  };

  useEffect(() => {
    setFormIsValid(
      !(
        firstName.isInvalid ||
        lastName.isInvalid ||
        email.isInvalid ||
        password.isInvalid ||
        repeatPassword.isInvalid
      )
    );
  }, [firstName, lastName, email, password, repeatPassword]);

  const footerDesc = (
    <span className="register__footer">
      Already have an account?{" "}
      <Link to="/login" state={{ from: origin }}>
        Sign&nbsp;in
      </Link>
    </span>
  );

  const handleFormChange = () => setFormWasChanged(() => true);

  return (
    <div className="register">
      <FormContainer
        title="Register"
        titleDesc="Let's get you on board."
        buttonType="submit"
        buttonIsDisabled={!formIsValid}
        buttonFunc={submitHandler}
        formId="register-form"
        footerDesc={footerDesc}
      >
        <form id="register-form" onChange={handleFormChange}>
          <div className="register__field">
            <FormField
              labelName="First Name"
              type="text"
              value={firstName?.value}
              onChange={updateFirstName}
              onBlur={(event) => validateField(event, setFirstName)}
              isInvalid={firstName?.isInvalid}
              errorMessage={firstName?.errorMessage}
            />
          </div>
          <div className="register__field">
            <FormField
              labelName="Last Name"
              type="text"
              value={lastName.value}
              onChange={updateLastName}
              onBlur={(event) => validateField(event, setLastName)}
              isInvalid={lastName.isInvalid}
              errorMessage={lastName.errorMessage}
            />
          </div>
          <div className="register__field register__field--long">
            <FormField
              labelName="Email"
              type="email"
              value={email.value}
              onChange={updateEmail}
              onBlur={(event) => validateField(event, setEmail)}
              isInvalid={email.isInvalid}
              errorMessage={email.errorMessage}
            />
          </div>
          <div className="register__field">
            <FormField
              labelName="Password"
              type="password"
              value={password.value}
              onChange={updatePassword}
              onBlur={(event) =>
                validateField(event, setPassword, {
                  match: repeatPassword.value,
                })
              }
              isInvalid={password.isInvalid}
              errorMessage={password.errorMessage}
            />
          </div>
          <div className="register__field">
            <FormField
              labelName="Repeat Password"
              type="password"
              value={repeatPassword.value}
              onChange={updateRepeatPassword}
              onBlur={(event) =>
                validateField(event, setRepeatPassword, {
                  match: password.value,
                })
              }
              isInvalid={repeatPassword.isInvalid}
              errorMessage={repeatPassword.errorMessage}
            />
          </div>
        </form>
      </FormContainer>
    </div>
  );
};

export default Register;
