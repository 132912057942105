import React from "react";
import PropTypes from "prop-types";

import { UserPhoto } from "components/UserPhoto";
import { SocialActions } from "components/SocialActions";
import { ReactComponent as ConfettiLeft } from "assets/bday-card/bday-content-top-left.svg";
import { ReactComponent as ConfettiRight } from "assets/bday-card/bday-content-top-right.svg";
import { ReactComponent as Petard } from "assets/bday-card/bday-content-petard.svg";
import { ReactComponent as Giftbox } from "assets/bday-card/bday-content-giftbox.svg";

import { shortMonth } from "utils/shortMonth";
import { daySuffix } from "utils/daySuffix";

import "./bday-card.scss";

export const BdayCard = ({
  name,
  birthDate,
  userImage,
  currentUserName,
  currentUserImage,
  wishes,
  comments,
}) => {
  const month = shortMonth(birthDate.getMonth());
  const day = birthDate.getDate();

  return (
    <div className="bday-card">
      <div className="bday-card__photo">
        <UserPhoto imagePath={userImage} />
      </div>
      <div className="bday-card__content">
        <div className="bday-card__decoration">
          <ConfettiLeft />
          <ConfettiRight />
        </div>
        <div className="bday-card__decoration">
          <Petard />
          <div className="bday-card__description">
            <div className="caption-two">{name}</div>
            <p>
              Celebrated a birthday on&nbsp;
              <b>
                {month}&nbsp;{day}
                {daySuffix(day)}
                <br />
                Send a wish!
              </b>
            </p>
          </div>
          <Giftbox />
        </div>
      </div>
      <SocialActions
        type="birthday"
        wishes={wishes}
        comments={comments}
        imagePath={currentUserImage}
        currentUserName={currentUserName}
        currentUserImage={currentUserImage}
      />
    </div>
  );
};

BdayCard.propTypes = {
  name: PropTypes.string.isRequired,
  birthDate: PropTypes.instanceOf(Date).isRequired,
  userImage: PropTypes.string.isRequired,
  currentUserName: PropTypes.string,
  currentUserImage: PropTypes.string,
  wishes: PropTypes.number.isRequired,
  comments: PropTypes.array.isRequired,
};
