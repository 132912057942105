import { useEffect, useState } from "react";

import { geoDistance } from "utils/geoDistance";

export const useSortByLocation = (data) => {
  const [sortedData, setSortedData] = useState();

  useEffect(() => {
    //get geolocation data
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // then sort the data array
        data.sort((a, b) => {
          const distance1 = geoDistance(
            a.location.coordinates.lat,
            a.location.coordinates.lng,
            position.coords.latitude,
            position.coords.longitude
          );
          const distance2 = geoDistance(
            b.location.coordinates.lat,
            b.location.coordinates.lng,
            position.coords.latitude,
            position.coords.longitude
          );
          return distance1 - distance2;
        });

        setSortedData({ data: data });
      },
      (error) => {
        setSortedData({ error: "ERROR: " + error.message });
      }
    );
  }, [data]);

  return sortedData;
};
