import React from "react";
import { Navigate } from "react-router";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "pages/Login";
import Register from "pages/Register";
import MainApp from "pages/MainApp";

import { AuthProvider } from "hooks/useAuth";

import { RequireAuth } from "components/RequireAuth";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route element={<RequireAuth />}>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/*" element={<MainApp />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
