import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./slider.scss";
import { SliderControls } from "components/SliderControls";
import { RestaurantCard } from "components/RestaurantCard/RestaurantCard";
export const Slider = ({ heading, modifiedArray }) => {
  const [containerWidth, setContainerWidth] = useState();
  const [isArrowDisabled, setIsArrowDisabled] = useState(false);
  const contentWidth = useRef();
  const transitionTime = 650;

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (contentWidth.current) {
        setContainerWidth(contentWidth.current.offsetWidth);
      }
    });
    resizeObserver.observe(contentWidth.current);
  }, []);

  const scrollNext = () => {
    contentWidth.current.scrollBy({
      left: containerWidth,
      behavior: "smooth",
    });
    setIsArrowDisabled(true);
    setTimeout(() => {
      setIsArrowDisabled(false);
    }, transitionTime);
  };

  const scrollPrev = () => {
    contentWidth.current.scrollBy({
      left: -containerWidth,
      behavior: "smooth",
    });
    setIsArrowDisabled(true);
    setTimeout(() => {
      setIsArrowDisabled(false);
    }, transitionTime);
  };

  return (
    <div className="slider-container">
      <div className="slider-container__header">
        <h2 className="slider-container__heading alt">{heading}</h2>
        <div className="controls">
          <SliderControls
            direction="prev"
            prevSlide={scrollPrev}
            isArrowDisabled={isArrowDisabled}
          ></SliderControls>
          <SliderControls
            direction="next"
            nextSlide={scrollNext}
            isArrowDisabled={isArrowDisabled}
          ></SliderControls>
        </div>
      </div>
      <div className="slider-container__content" ref={contentWidth}>
        {modifiedArray?.map((restaurant, index) => {
          return (
            <div className="slider-container__wrapper" key={index}>
              <RestaurantCard
                id={restaurant.id}
                openingHours={restaurant.openingHours}
                categories={restaurant.categories}
                name={restaurant.name}
                checkIns={restaurant.checkIns}
                reviews={restaurant.reviews}
                image={restaurant.image}
                website={restaurant.website}
                address={restaurant.location.address}
                coordinates={restaurant.location.coordinates}
                description={restaurant.description}
                expand={true}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

Slider.propTypes = {
  modifiedArray: PropTypes.array,
  heading: PropTypes.string,
};
