import React from "react";
import PropTypes from "prop-types";
import "./dots.scss";

export const Dots = ({ slides, activeDot, setVisibleSlide }) => {
  return (
    <div className="dot-container">
      {slides.map((_element, index) => {
        if (index === 0 || index === slides.length - 1) {
          return null;
        }
        return (
          <button
            key={index}
            className={`dot-container__dot ${
              activeDot(index) ? "dot-container__dot--active" : ""
            }`}
            onClick={() => setVisibleSlide(index)}
            aria-label={`Show ${_element.name} slide`}
          ></button>
        );
      })}
    </div>
  );
};

Dots.propTypes = {
  slides: PropTypes.array,
  activeDot: PropTypes.func,
  setVisibleSlide: PropTypes.func,
};
