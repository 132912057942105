import React, { useState, useEffect, useContext } from "react";

import "./restaurants-widget.scss";

import { RestaurantCard } from "components/RestaurantCard/RestaurantCard";
import { BrowseRestaurantsCard } from "components/BrowseRestaurantsCard/BrowseRestaurantsCard";

import { UserData } from "context/UserData";

export const RestaurantsWidget = () => {
  const userData = useContext(UserData);
  const [restaurants, setRestaurants] = useState(null); // null
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!userData.isLoading) {
      // get Restaurants data
      fetch(
        `http://frontendsourceryweb.s3-website.eu-central-1.amazonaws.com/restaurants.json`
      )
        .then((response) => response.json())
        .then((responseData) => {
          const fullRestaurantsArray = responseData.restaurants;
          const userLikedRestaurantsIdArray = userData.data.liked.restaurants;

          const userLikedRestaurants = fullRestaurantsArray.filter((array) =>
            userLikedRestaurantsIdArray.some((filter) => filter.id === array.id)
          );

          return setRestaurants(userLikedRestaurants);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        })
        .finally(() => setIsLoading(false));
    }
  }, [userData]);

  return (
    <div className="restaurants-widget">
      {isLoading && <div>Loading data ...</div>}
      {!isLoading && (
        <>
          <BrowseRestaurantsCard />
          {errorMessage && errorMessage}
          {restaurants?.slice(0, 2).map((restaurant) => (
            <div key={restaurant.id}>
              <RestaurantCard
                id={restaurant.id}
                openingHours={restaurant.openingHours}
                categories={restaurant.categories}
                name={restaurant.name}
                checkIns={restaurant.checkIns}
                reviews={restaurant.reviews}
                image={restaurant.image}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
