import React from "react";
import PropTypes from "prop-types";

import "./user-profile-icon.scss";

import { ReactComponent as UserDefault } from "../../assets/icons/user/user-default.svg";
import { UserPhoto } from "components/UserPhoto";

export const UserProfileIcon = ({ imagePath, onClick }) => {
  return (
    <button className="user-profile-icon" type="button" onClick={onClick}>
      {imagePath ? (
        <UserPhoto imagePath={imagePath} />
      ) : (
        <UserDefault className="user-profile-icon__no-photo" />
      )}
    </button>
  );
};

UserProfileIcon.propTypes = {
  imagePath: PropTypes.string,
  onClick: PropTypes.func,
};
