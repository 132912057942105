import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

import "./card-media.scss";

export const CardMedia = ({ link, type }) => {
  const contentDescription = "user supplied " + type;

  return (
    <div className="card-media">
      {type === "post" ? (
        <img src={link} alt={contentDescription} />
      ) : (
        <ReactPlayer
          url={link}
          alt={contentDescription}
          controls={true}
          width="100%"
        />
      )}
    </div>
  );
};

CardMedia.propTypes = {
  link: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["post", "video"]).isRequired,
};
