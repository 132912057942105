import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./card.scss";

export const Card = ({ title, count, text, cssModifier, icon }) => {
  const categoryPath = title.toLocaleLowerCase().replaceAll(" ", "-");

  return (
    <li className={`card ${cssModifier ? "card--" + cssModifier : ""}`}>
      <Link to={`/eat-out/${categoryPath}`}>
        <div>
          <h2 className="card__title alt">{title}</h2>
          <p className="card__text caption-two">
            {count}&nbsp;{text}
          </p>
        </div>
        {icon}
      </Link>
    </li>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
  text: PropTypes.string,
  cssModifier: PropTypes.string,
  icon: PropTypes.element,
};
