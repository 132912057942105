import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/Button";

import "./restaurant-action-bar.scss";
import { Link } from "react-router-dom";
import { RatingCard } from "components/RatingCard";
import { SocialButton } from "components/SocialButton/SocialButton";
import { useState } from "react";
import { useEffect } from "react";

export const RestaurantActionBar = ({ restaurant }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [like, setLike] = useState(false);
  const [groupSize, setGroupSize] = useState("");
  useEffect(() => {
    if (restaurant.checkIns > 1) {
      setGroupSize(
        `${
          restaurant.checkIns + (isChecked ? 1 : 0)
        } people already checked-in!`
      );
    } else if (restaurant.checkIns < 1) {
      setGroupSize(`Be the first to check-in!`);
    } else {
      setGroupSize(`One person already checked-in!`);
    }
  }, [restaurant.checkIns, isChecked]);
  const toggleLike = () => {
    setLike((previous) => !previous);
  };
  const toggleCheckIn = () => {
    setIsChecked((previous) => !previous);
  };

  const btnTitle = `check-${isChecked ? "out" : "in"}`;

  return (
    <section className="restaurant-action-bar">
      <div className="restaurant-action-bar__evaluate-restaurant">
        <RatingCard reviews={restaurant.reviews} />
        <SocialButton isIconClicked={like} handleIconToggle={toggleLike} />
      </div>
      <p className="restaurant-action-bar__group-size">{groupSize}</p>
      <div className="restaurant-action-bar__check-in">
        <Link
          to=""
          className="addon__restaurant-page caption-two"
          onClick={(event) => event.preventDefault()}
        >
          Invite
        </Link>
        <Button
          title={btnTitle}
          type="button"
          size="medium"
          func={toggleCheckIn}
        />
      </div>
    </section>
  );
};

RestaurantActionBar.propTypes = {
  restaurant: PropTypes.shape({
    checkIns: PropTypes.number.isRequired,
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        userName: PropTypes.string,
        id: PropTypes.string,
        comment: PropTypes.string,
        rating: PropTypes.number.isRequired,
      })
    ).isRequired,
  }),
};
