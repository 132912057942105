import React from "react";
import PropTypes from "prop-types";

import "./restaurant-hero-section.scss";

import { TagCard } from "components/TagCard/TagCard";

export const RestaurantHeroSection = ({
  title = "Grill ondon (KAuno mega)",
  categories = ["Steak", "Pizza", "Salad"],
  image = "https://images.unsplash.com/photo-1498579485796-98be3abc076e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80",
}) => {
  return (
    <div className="restaurant-hero-section">
      <div className="restaurant-hero-section__bg">
        <img src={image} alt="restaurant cover" />
      </div>
      <div className="restaurant-hero-section__content">
        <div className="restaurant-hero-section__tags">
          {categories.map((category) => (
            <TagCard key={category} category={category} />
          ))}
        </div>
        <h1 className="restaurant-hero-section__title">{title}</h1>
      </div>
    </div>
  );
};

RestaurantHeroSection.propTypes = {
  categories: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
