import React from "react";

import { UserDataProvider } from "context/UserData";
import { AppRoutes } from "Routes";
import { Logo } from "components/Logo";
import { Navigation } from "components/Navigation";
import { Breadcrumbs } from "components/Breadcrumbs";
import { UserProfileWidget } from "components/UserProfileWidget";
import { getYear } from "utils/getYear";
import { NavLink } from "react-router-dom";

const MainApp = () => {
  const year = getYear();

  return (
    <UserDataProvider>
      <header className="header">
        <NavLink
          to="/dashboard"
          className="logo"
          aria-label="Go to dashboard page"
        >
          <Logo />
        </NavLink>
        <Navigation />
        <UserProfileWidget />
      </header>
      <main className="container">
        <Breadcrumbs />
        <AppRoutes />
      </main>
      <footer className="footer">Copyright &copy; {year} Devbridge</footer>
    </UserDataProvider>
  );
};

export default MainApp;
