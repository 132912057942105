import React from "react";
import PropTypes from "prop-types";

import "./form-container.scss";

import { Button } from "components/Button/Button";
import { Logo } from "components/Logo/Logo";

export const FormContainer = ({
  title,
  titleDesc,
  buttonType,
  buttonTitle,
  buttonIsDisabled,
  buttonFunc,
  formId,
  footerDesc,
  children,
}) => {
  return (
    <div className="form-container">
      <div className="form-container__wrapper">
        <div className="form-container__card">
          <div className="form-container__top-container">
            <header>
              <h2 className="form-container__title alt">{title}</h2>
              <p className="form-container__title-desc">{titleDesc}</p>
            </header>
            <div className="form-container__card-logo">
              <Logo isMonochrome={false} />
            </div>
          </div>

          <main>{children}</main>

          <footer>
            <Button
              title={buttonTitle ? buttonTitle : title}
              size="large"
              type={buttonType}
              isDisabled={buttonIsDisabled}
              form={formId}
              func={buttonFunc}
            >
              {buttonTitle ? buttonTitle : title}
            </Button>
            <p>{footerDesc}</p>
          </footer>
        </div>
      </div>
    </div>
  );
};

FormContainer.propTypes = {
  title: PropTypes.string.isRequired,
  titleDesc: PropTypes.string,
  buttonType: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonIsDisabled: PropTypes.bool,
  buttonFunc: PropTypes.func,
  formId: PropTypes.string,
  footerDesc: PropTypes.node,
  children: PropTypes.node,
};
