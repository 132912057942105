import React, { useState, useEffect } from "react";
import { Categories } from "components/Categories";
import { ErrorMessage } from "components/ErrorMessage";
import { NearestRestaurants } from "components/NearestRestaurants";
import { HeroSlider } from "components/HeroSlider/";
import { NewestRestaurants } from "components/NewestRestaurants";
import "./eat-out.scss";

const EatOut = () => {
  const [restaurants, setRestaurants] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  let eatOutContent = "";

  useEffect(() => {
    // get Restaurants data
    fetch(
      `http://frontendsourceryweb.s3-website.eu-central-1.amazonaws.com/restaurants.json`
    )
      .then((response) => response.json())
      .then((responseData) => setRestaurants(responseData.restaurants))
      .catch((error) => {
        setErrorMessage(error.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    eatOutContent = <h2 className="alt">Restaurants are loading...</h2>;
  } else if (!restaurants) {
    eatOutContent = <ErrorMessage errorMessage={errorMessage} />;
  } else {
    eatOutContent = (
      <>
        <HeroSlider restaurants={restaurants} />
        <Categories restaurants={restaurants} />
        <NearestRestaurants restaurants={restaurants} />
        <NewestRestaurants restaurants={restaurants} />
      </>
    );
  }

  return (
    <div className="eat-out">
      <h1 className="eat-out__header">Hungry? Find the best place!</h1>
      <section className="eat-out__content">{eatOutContent}</section>
    </div>
  );
};

export default EatOut;
