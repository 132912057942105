import React, { useEffect, useState } from "react";
import moment from "moment";

import "./weather-widget.scss";

import { ReactComponent as Droplet } from "../../assets/weather-widget/weather-icons/droplet.svg";
import { ReactComponent as Wind } from "../../assets/weather-widget/weather-icons/wind.svg";

import { weatherCondition } from "utils/weatherCondition";

export const WeatherWidget = () => {
  const [weatherData, setWeatherData] = useState([]);
  const [userAddress, setUserAddress] = useState("");

  useEffect(() => {
    //get geolocation data
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;
        fetch(
          //API USED https://rapidapi.com/weatherapi/api/weatherapi-com/
          `https://weatherapi-com.p.rapidapi.com/current.json?q=${latitude}%2C${longitude}`,
          {
            method: "GET",
            headers: {
              "x-rapidapi-host": "weatherapi-com.p.rapidapi.com",
              "x-rapidapi-key":
                "26c51f6b67msh8a1d0ef7d6b25b5p1b592cjsn2f6ad44583f4",
            },
          }
        )
          .then((response) => response.json())
          .then((data) => setWeatherData(data))
          .catch((error) => {
            window.alert("ERORR: couldn't load weather data");
          });
      },
      (error) => {
        //execute if user declines to turn on geolocation
        if (error.code === error.PERMISSION_DENIED) {
          window.alert("GEOLOCATION PERMISSION DENIED"); //fetch "userData" if user declines to turn on geolocation
          fetch(
            `http://frontendsourceryweb.s3-website.eu-central-1.amazonaws.com/userData.json`
          )
            .then((response) => response.json())
            .then((data) =>
              setUserAddress(
                data.userData[0].location
                  .normalize("NFD")
                  .replaceAll(/[\u0300-\u036f]/g, "") //removes accents/diacritic characters
                  .replaceAll(" ", "%20") //replaces spaces with "%20"
                  .replaceAll(",", "%2C") //replace "," with "%2C"
              )
            )
            .catch(
              (error) =>
                window.alert(
                  "/userData fetch failed. default location set: Kaunas"
                ),
              setUserAddress("Kaunas") //set userAddress if user declines geolocation and "/userData" fetch fails
            );
        }
      }
    );
  }, []);

  useEffect(() => {
    if (userAddress !== "") {
      fetch(
        //API USED https://rapidapi.com/weatherapi/api/weatherapi-com/
        `https://weatherapi-com.p.rapidapi.com/current.json?q=${userAddress}`,
        {
          method: "GET",
          headers: {
            "x-rapidapi-host": "weatherapi-com.p.rapidapi.com",
            "x-rapidapi-key":
              "26c51f6b67msh8a1d0ef7d6b25b5p1b592cjsn2f6ad44583f4",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => setWeatherData(data))
        .catch((error) => {
          window.alert("error: couldn't load weather data");
        });
    }
  }, [userAddress]);

  const { location, current } = weatherData;

  if (!location && !current)
    return (
      <div className="weather-widget">
        <span>loading weather data...</span>
      </div>
    );

  let time = moment.unix(location.localtime_epoch).format("dddd, D MMMM "); //example result = Monday, 13 December
  let currentWind = current.wind_kph / 3.6; // kph converts to m/s
  let currentWindRounded = currentWind.toFixed(1); // rounds to 1 decimal numbers

  return (
    <div className="weather-widget">
      <div className="weather-widget__card">
        <div className="weather-widget__location-date">
          <span>{time}</span>
          <span className="weather-widget__location-date-divider">|</span>
          <span>
            {location.name},&nbsp;{location.country}
          </span>
        </div>
        <div className="weather-widget__weather-data">
          <div className="weather-widget__weather-base">
            <span className="weather-widget__weather-temperature">
              {current.temp_c}°
            </span>
            <span className="weather-widget__weather-condition">
              {current.condition.text}
            </span>
          </div>
          <div className="weather-widget__weather-statistics">
            <span className="weather-widget__stat">
              <Wind className="weather-widget__stat-icon" />
              <span className="weather-widget__stat-text">
                {currentWindRounded}&nbsp;m/s
              </span>
            </span>
            <span className="weather-widget__stat">
              <Droplet className="weather-widget__stat-icon" />
              <span className="weather-widget__stat-text">
                {current.precip_mm}&nbsp;mm
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="weather-widget__illustration">
        <span className="weather-widget__weather-icon">
          {weatherCondition(current.condition.code)}
        </span>
      </div>
    </div>
  );
};
