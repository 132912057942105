import React, { useState, useEffect } from "react";
import { SocialButton } from "components/SocialButton";
import PropTypes from "prop-types";
import "./social-buttons-container.scss";

export const SocialButtonsContainer = ({
  cardType,
  wishes,
  likes,
  commentsQty,
  commentInputToggler,
}) => {
  const initialToggleCount = wishes || likes;
  const [toggleCount, setToggleCount] = useState(initialToggleCount);
  const [isIconClicked, setIsIconClicked] = useState(false);

  const handleIconToggle = () => {
    setIsIconClicked(!isIconClicked);
  };

  useEffect(() => {
    const test = () => {
      if (isIconClicked) {
        setToggleCount((t) => t + 1);
      } else {
        setToggleCount(initialToggleCount);
      }
    };
    test();
  }, [isIconClicked, initialToggleCount]);

  return (
    <>
      <div className="social-button-group">
        <SocialButton
          iconType={cardType}
          isIconClicked={isIconClicked}
          handleIconToggle={handleIconToggle}
        />
        <span className="caption-two counter">{toggleCount}</span>
      </div>
      <div className="social-button-group social-button-group--comment">
        <SocialButton
          iconType="comment"
          commentsQty={commentsQty}
          commentInputToggler={commentInputToggler}
        />
        <span className="caption-two counter">{commentsQty}</span>
      </div>
    </>
  );
};

SocialButtonsContainer.propTypes = {
  cardType: PropTypes.string,
  wishes: PropTypes.number,
  likes: PropTypes.number,
  commentsQty: PropTypes.number,
  commentInputToggler: PropTypes.func,
};
