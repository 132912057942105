import React from "react";

import { ReservationsWidget } from "components/ReservationsWidget";

const Reservations = () => {
  return (
    <>
      <h1>Reservations</h1>
      <ReservationsWidget hideQty />
    </>
  );
};

export default Reservations;
