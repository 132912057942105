import React from "react";
import PropTypes from "prop-types";
import { Slider } from "components/Slider";

export const NewestRestaurants = ({ restaurants }) => {
  const modifiedArray = JSON.parse(JSON.stringify(restaurants));

  modifiedArray.sort(
    (a, b) =>
      new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf()
  );

  return <Slider heading={"New places"} modifiedArray={modifiedArray}></Slider>;
};

NewestRestaurants.propTypes = {
  restaurants: PropTypes.array,
};
