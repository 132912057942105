import React from "react";
import { useLocation, Link } from "react-router-dom";

import "./breadcrumbs.scss";

export const Breadcrumbs = () => {
  //gets location object
  const location = useLocation();

  // gets pathname param from location object
  // for example: `eat-out/ewRcTre` then we are on `baseurl/eat-out/ewRcTre`
  const { pathname } = location;

  // splits pathArray after every `/` symbol
  // for example: pathname `eat-out/ewRcTre` becomes `['eat-out', 'ewRcTre']`
  const pathArray = pathname.split("/").filter((item) => item);

  // finds if there is a path item `restaurants` in the array.
  const indexOfRestaurantsItem = pathArray.indexOf("restaurants");
  if (location?.state?.name && indexOfRestaurantsItem >= 0) {
    // If found, replaces the following id with the corresponding name
    // received with the location object (sent from RestaurantCard or HeroSlider)...
    pathArray[indexOfRestaurantsItem + 1] = location.state.name;
    // ...and removes the `restaurants` array item itself
    pathArray.splice(indexOfRestaurantsItem, 1);
  }

  return (
    <nav aria-label="Breadcrumb" className="breadcrumbs">
      <ol className="breadcrumbs__list">
        {pathArray.map((item, index) => {
          //slices pathArray items and joins them with `/`
          //for example ['eat-out'] becomes `/eat-out`
          //for example ['eat-out', 'Avilys'] becomes `/eat-out/Avilys`
          const routeTo = `/${pathArray.slice(0, index + 1).join("/")}`;
          const isLast =
            index === pathArray.length - 1 || pathArray.length === 1;
          return (
            <li key={item} className="breadcrumbs__item">
              {isLast ? (
                <span
                  aria-current="location"
                  className="breadcrumbs__link breadcrumbs__link--current"
                >
                  {item}
                </span>
              ) : (
                <Link
                  to={`${routeTo}`}
                  aria-current="location"
                  className="breadcrumbs__link"
                >
                  {item}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
