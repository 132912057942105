import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

export const RequireAuth = () => {
  const { authed } = useAuth();
  let location = useLocation();

  if (!authed) {
    // Redirect to the /login page, but save the current location of the page
    // trying to get access as it was redirected
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
};
