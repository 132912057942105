import React from "react";
import PropTypes from "prop-types";
import { Slider } from "components/Slider";
import { useSortBySimilarity } from "hooks/useSortBySimilarity";
import "./similar-restaurants.scss";

export const SimilarRestaurants = ({ criteria, restaurants }) => {
  const modifiedArray = useSortBySimilarity(criteria, restaurants);

  return modifiedArray.length > 0 ? (
    <div className="similar-restaurants">
      <Slider
        heading="Also you could like"
        modifiedArray={modifiedArray}
      ></Slider>
    </div>
  ) : (
    <h2 className="alt">There&apos;s no place like this</h2>
  );
};

SimilarRestaurants.propTypes = {
  criteria: PropTypes.object,
  restaurants: PropTypes.array,
};
