import React from "react";
import { ReactComponent as Rain } from "../assets/weather-widget/weather-illustration/weather-widget_rain.svg";
import { ReactComponent as Snow } from "../assets/weather-widget/weather-illustration/weather-widget_snow.svg";
import { ReactComponent as SunCloud } from "../assets/weather-widget/weather-illustration/weather-widget_sun-cloud.svg";
import { ReactComponent as Sun } from "../assets/weather-widget/weather-illustration/weather-widget_sun.svg";
import { ReactComponent as Thunder } from "../assets/weather-widget/weather-illustration/weather-widget_thunder.svg";

//condition codes are taked from api documentation
//images assigned to code according to images/csv day/night descriptions
//https://www.weatherapi.com/docs/#weather-icons
export const weatherCondition = (condition) => {
  switch (condition) {
    case 1000:
      return <Sun />;
    case 1003:
    case 1210:
      return <SunCloud />;
    case 1087:
    case 1273:
    case 1276:
    case 1279:
    case 1282:
      return <Thunder />;
    case 1006:
    case 1009:
    case 1030:
    case 1066:
    case 1069:
    case 1072:
    case 1114:
    case 1117:
    case 1213:
    case 1216:
    case 1219:
    case 1222:
    case 1225:
    case 1237:
    case 1255:
    case 1258:
    case 1261:
    case 1264:
      return <Snow />;
    default:
      return <Rain />;
  }
};
