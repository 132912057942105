import React, { useState, useRef, useEffect, useContext } from "react";
import classNames from "classnames";

import { UserProfileIcon } from "./UserProfileIcon";
import { UserProfileMenu } from "./UserProfileMenu";
import { ReactComponent as Chevron } from "../../assets/icons/chevron_down.svg";

import "./user-profile-widget.scss";

import { UserData } from "../../context";
import { useAuth } from "hooks/useAuth";

export const UserProfileWidget = () => {
  const { logout } = useAuth();

  const userData = useContext(UserData);
  const [isActive, setIsActive] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isActive && ref.current && !ref.current.contains(e.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive]);

  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  const closeDropdown = () => {
    logout();
    setIsActive(false);
  };

  return (
    !userData.isLoading && (
      <div
        ref={ref}
        className={classNames("user-profile-widget", isActive && "is-active")}
      >
        <UserProfileIcon
          imagePath={userData.data.userImage}
          onClick={toggleDropdown}
        />
        <div className="user-profile-widget__chevron">
          <Chevron />
        </div>
        {isActive && <UserProfileMenu onClick={closeDropdown} />}
      </div>
    )
  );
};
