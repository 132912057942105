import React from "react";
import PropTypes from "prop-types";
import "./tag-card.scss";

export const TagCard = ({ category }) => {
  return <span className="tag-card">{category} </span>;
};

TagCard.propTypes = {
  category: PropTypes.string.isRequired,
};
