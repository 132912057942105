const validate = (validations) => {
  for (const validation of validations) {
    if (validation.rule) {
      return validation.message;
    }
  }
};

const simpleValidationRules = (value) => [
  { rule: value === "", message: "must be filled" },
];

const nameValidationRules = (value) => [
  { rule: value === "", message: "must be filled" },
  { rule: !value.match(/^[a-žA-Ž]+$/), message: "Only letters alowed" },
  {
    rule: !value.match(/^[a-žA-Ž]{2,30}$/),
    message: "length should be 2-30 characters",
  },
];

const emailValidationRules = (value) => [
  { rule: value === "", message: "must be filled" },
  {
    rule: !value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g),
    message: "email is invalid",
  },
];

const passwordValidationRules = (value) => [
  { rule: value === "", message: "must be filled" },
  {
    rule: !value.match(/^(?=.*\d)(?=.*[a-ž])(?=.*[A-Ž])(?=.*[a-žA-Ž]).{8,}$/gm),
    message: "use strong password",
  },
];

export const fieldTemplate = {
  value: "",
  isInvalid: null,
  errorMessage: null,
};

export const validateField = (event, updateFn, options) => {
  const currValue = event.target.value;
  const currId = event.target.id;

  let validationRules;

  if (options?.simple) {
    validationRules = simpleValidationRules(currValue);
  } else {
    switch (currId) {
      case "first-name":
      case "last-name":
        validationRules = nameValidationRules(currValue);
        break;
      case "email":
        validationRules = emailValidationRules(currValue);
        break;
      case "password":
      case "repeat-password":
        validationRules = passwordValidationRules(currValue);
        break;
      default:
    }
  }

  if (options?.match) {
    validationRules.push({
      rule: currValue !== options?.match,
      message: "passwords do not match",
    });
  }

  const validationMessage = validate(validationRules);

  const newValueObj = {
    value: currValue,
    isInvalid: !!validationMessage,
    errorMessage: validationMessage,
  };

  updateFn(newValueObj);
};
