import React, { useContext } from "react";
import { useParams } from "react-router";

import { UserData } from "context";

const Reservation = () => {
  const userData = useContext(UserData);
  const { name } = useParams(); //get dynamic selected id
  while (userData.isLoading) {
    return <div>LOADING USER DATA...</div>;
  }
  const user = userData.data;

  const reservations = user.reservations; // assign reservations data

  const reservation = reservations[name]; // reservations for the selected type

  return (
    <div>
      <h3>
        Reservations of {user.userName} for {name}:
      </h3>
      <ul>
        {reservation.map((item) => (
          <li key={item.id}>{item.id}</li>
        ))}
      </ul>
    </div>
  );
};

export default Reservation;
