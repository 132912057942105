import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "hooks/useAuth";

export const UserData = createContext();

export const UserDataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { authed } = useAuth();

  useEffect(() => {
    // get user data
    if (authed) {
      fetch(
        `http://frontendsourceryweb.s3-website.eu-central-1.amazonaws.com/userData.json`
      )
        .then((response) => response.json())
        .then((responseData) => {
          setData(responseData.userData[0]);
          setIsLoading(false);
        })
        .catch((error) => window.alert("ERORR: couldn't load user data"));
    }
  }, [authed]);

  return (
    <UserData.Provider value={{ data, isLoading }}>
      {children}
    </UserData.Provider>
  );
};

UserDataProvider.propTypes = {
  children: PropTypes.node,
};
