import React from "react";
import PropTypes from "prop-types";

import "./restaurant-location.scss";
export const RestaurantLocation = ({ mapZoomLevel, coordinates }) => {
  const mapURL =
    "https://api.mapbox.com/styles/v1/adarkmap/ckxqos2by6as914o5e9mgzzfh.html?title=false&access_token=pk.eyJ1IjoiYWRhcmttYXAiLCJhIjoiY2t4cW9yYnpkMHBvdzJwcDR5Y2ZvOG8wbiJ9.PPaYEoNRP2XRAaeG3kVUvA&zoomwheel=false#";
  return (
    <section className="restaurant-location">
      <h2 className="restaurant-location__title alt">Location</h2>
      <iframe
        className="restaurant-location__map"
        src={`${mapURL}${mapZoomLevel}/${coordinates.lat}/${coordinates.lng}`}
        title="Restaurant Location"
      ></iframe>
    </section>
  );
};

RestaurantLocation.propTypes = {
  coordinates: PropTypes.objectOf(PropTypes.number).isRequired,
  mapZoomLevel: PropTypes.number.isRequired,
};
