import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import { SocialButtonsContainer } from "components/SocialButtonsContainer";
import { CommentsList } from "components/CommentsList";
import { CommentInput } from "components/CommentInput";

import "./social-actions.scss";

import { useOnClickOutside } from "../../hooks/useOnClickOutside";

export const SocialActions = ({
  type,
  wishes,
  likes,
  comments,
  currentUserName,
  currentUserImage,
}) => {
  const [usersComments, setUsersComments] = useState(comments);
  const [userInput, setUserInput] = useState("");
  const [isCommentInputOpen, setIsCommentInputOpen] = useState(false);

  // "clicked-outside" function
  const ref = useRef();
  useOnClickOutside(ref, () => setIsCommentInputOpen(false));

  const hanldeUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const resetUserInputValue = () => {
    setUserInput("");
  };

  const getFormattedDate = (localeDateString) => {
    const regex = /(\d*\/\d*\/\d{4}), (\d*:\d*):\d{1,2} (PM|AM)/;
    const date = localeDateString.replace(regex, "$1 $2 $3");
    return date;
  };

  const commentInputToggler = () => {
    setIsCommentInputOpen(!isCommentInputOpen);
  };

  const handleCommentPostClick = () => {
    if (userInput.trim()) {
      const userInputDate = new Date().toLocaleString();

      setUsersComments([
        ...usersComments,
        {
          userName: currentUserName,
          comment: userInput.trim(),
          date: getFormattedDate(userInputDate),
        },
      ]);
      resetUserInputValue();
      commentInputToggler();
    } else {
      return;
    }
  };

  return (
    <>
      <div className="social-actions" ref={ref}>
        <div className="social-actions__container">
          <SocialButtonsContainer
            cardType={type}
            wishes={wishes}
            likes={likes}
            commentsQty={usersComments.length}
            commentInputToggler={commentInputToggler}
          />
        </div>
        {!!usersComments.length && (
          <div className="social-actions__container">
            <CommentsList
              commentsList={usersComments}
              getFormattedDate={getFormattedDate}
            />
          </div>
        )}
        {isCommentInputOpen && (
          <div className="social-actions__container">
            <CommentInput
              hanldeUserInput={hanldeUserInput}
              handleClick={handleCommentPostClick}
              inputValue={userInput}
              imagePath={currentUserImage}
              ariaExpanded={isCommentInputOpen}
            />
          </div>
        )}
      </div>
    </>
  );
};

SocialActions.propTypes = {
  type: PropTypes.string,
  wishes: PropTypes.number,
  likes: PropTypes.number,
  comments: PropTypes.array,
  currentUserName: PropTypes.string,
  currentUserImage: PropTypes.string,
};
