import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./button.scss";

export const Button = ({ title, size, isDisabled, icon, type, form, func }) => {
  switch (size) {
    case "large":
      size = "button--large";
      break;
    case "medium":
      size = "button--medium";
      break;
    default:
      break;
  }

  return (
    <button
      onClick={func}
      className={classNames(
        `button`,
        `${size}`,
        isDisabled && "button--disabled"
      )}
      disabled={isDisabled}
      type={type}
      form={form}
    >
      {icon && <span className="button__icon">{icon}</span>}
      <span className="button__title">{title.toUpperCase()}</span>
    </button>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  icon: PropTypes.element,
  type: PropTypes.oneOf(["submit", "reset", "button"]),
  form: PropTypes.string,
  func: PropTypes.func.isRequired,
};
