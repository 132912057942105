import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as StarIconColored } from "../../assets/icons/icon-star-colored.svg";

import "./review-card.scss";

export const ReviewCard = ({ review }) => {
  const { userName, comment, rating } = review;

  return (
    <li className="review-card">
      <div className="review-card__rating">
        <StarIconColored className="review-card__star-icon" />
        <span className="review-card__rating-value">{rating.toFixed(1)}</span>
      </div>
      <div className="review-card__author">{userName}</div>
      <p className="review-card__text">{comment}</p>
    </li>
  );
};

ReviewCard.propTypes = {
  review: PropTypes.object,
};
