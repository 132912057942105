import React, { useState, createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";

// create the context
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // using the useState hook to keep track of the value authed (if a user is logged in)
  const [authed, setAuthed] = useState(localStorage.getItem("loggedIn"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!authed) {
      fakeAsyncLoginCheck().then((activeUser) => {
        if (activeUser) {
          setAuthed(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  }, [authed]);

  const login = async (userDetails) => {
    const result = await fakeAsyncLogin(userDetails);

    if (result) {
      localStorage.setItem("loggedIn", true);
      setAuthed(true);
    }
  };

  const logout = async () => {
    const result = await fakeAsyncLogout();

    if (result) {
      localStorage.removeItem("loggedIn");
      setAuthed(false);
    }
  };

  // mock async Login API call: to be replaced with actual login API call code
  const fakeAsyncLogin = async (messageBody) => {
    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("The user has successfully logged in on the server");
      }, 300);
    });
  };

  // fixes the reload issue
  const fakeAsyncLoginCheck = async () => {
    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 300);
    });
  };

  // mock async Logout API call: to be replaced with actual logout API call code
  const fakeAsyncLogout = async () => {
    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("The user has successfully logged out of the server");
      }, 300);
    });
  };

  return (
    // using the provider so that ANY component in our application can
    // use the values that we are sending
    <AuthContext.Provider value={{ authed, setAuthed, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

// creating the custom hook
export const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
