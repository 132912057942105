export const getWeekdays = (weekdays) => {
  var week = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const d = new Date();
  let day = week[d.getDay()]; //gets today date as weekday (for example 'Saturday' or 'Friday')
  let time = d.getHours() + ":" + d.getMinutes();

  for (var j = 0; j < weekdays.length; j++) {
    let firstWeekday = weekdays[j]["days"].split(" ")[0]; //take first word (first weekday) from input
    let lastWeekday = weekdays[j]["days"].split(" ").pop(); // take last word (last weekday) from input

    let firstIndex = week.indexOf(firstWeekday); //find first day in 'week' array
    week = week.concat(week.splice(0, firstIndex)); //shift array so that first day is index 0
    let lastIndex = week.indexOf(lastWeekday); //find last day in 'week' array

    let workingDays = week.slice(0, lastIndex + 1); //cut 'week' array from first day to last day
    if (workingDays.includes(day)) {
      let openingHours = weekdays[j]["hours"].split(" ")[0]; //take first word (first hour) from input
      let closingHours = weekdays[j]["hours"].split(" ").pop(); // take last word (last hour) from input

      if (time >= openingHours && time <= closingHours) {
        return weekdays[j]["hours"];
      } else {
        return "currently closed";
      }
    }
  }
  return "currently closed";
};
