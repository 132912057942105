import React from "react";
import PropTypes from "prop-types";

import "./post-card.scss";

import { PostCardHeader } from "components/PostCardHeader";
import { CardMedia } from "components/CardMedia";
import { SocialActions } from "components/SocialActions";

export const PostCard = ({
  name,
  userImage,
  imgLink,
  imgType,
  location,
  date,
  comments,
  likes,
  currentUserName,
  currentUserImage,
}) => {
  return (
    <div className="post-card">
      <PostCardHeader
        name={name}
        imgLink={userImage}
        location={location}
        date={date}
      />
      <CardMedia link={imgLink} type={imgType} />

      <SocialActions
        type="post"
        likes={likes}
        comments={comments}
        imagePath={currentUserImage}
        currentUserName={currentUserName}
        currentUserImage={currentUserImage}
      />
    </div>
  );
};

PostCard.propTypes = {
  name: PropTypes.string.isRequired,
  userImage: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  imgLink: PropTypes.string.isRequired,
  imgType: PropTypes.oneOf(["post", "video"]).isRequired,
  location: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
  currentUserName: PropTypes.string,
  currentUserImage: PropTypes.string,
};
