import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ReviewCard } from "components/ReviewCard";
import { Button } from "components/Button";
import "./reviews-widget.scss";

export const ReviewsWidget = ({ reviews }) => {
  const allReviews = reviews;
  const [renderedReviewsCount, setRenderedReviewsCount] = useState(3);
  const [renderedReviews, setRenderedreviews] = useState(
    allReviews.slice(0, renderedReviewsCount)
  );

  const handleShowMore = () => {
    let notRenderedCommentsCount = allReviews.length - renderedReviewsCount;
    notRenderedCommentsCount >= 3
      ? setRenderedReviewsCount(renderedReviewsCount + 3)
      : setRenderedReviewsCount(
          renderedReviewsCount + notRenderedCommentsCount
        );
  };

  useEffect(() => {
    setRenderedreviews(allReviews.slice(0, renderedReviewsCount));
  }, [allReviews, renderedReviewsCount]);

  if (!reviews.length) {
    return (
      <aside className="reviews-widget">
        <h2 className="reviews-widget__title alt">Reviews</h2>
        <h3 className="reviews-widget__content alt">
          There is no reviews for this place. Be first to review!
        </h3>
      </aside>
    );
  }

  return (
    <aside className="reviews-widget">
      <ul className="reviews-widget__content">
        {renderedReviews.map((review) => (
          <ReviewCard key={review.id} review={review} />
        ))}
      </ul>
      {reviews.length > 3 && (
        <Button
          title="Show more"
          size="medium"
          type="button"
          isDisabled={allReviews.length === renderedReviewsCount}
          func={handleShowMore}
        />
      )}
    </aside>
  );
};

ReviewsWidget.propTypes = {
  reviews: PropTypes.array,
};
