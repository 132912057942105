import React from "react";
import { Link } from "react-router-dom";

import "./browse-restaurants-card.scss";

export const BrowseRestaurantsCard = () => {
  return (
    <div className="browse-restaurants-card">
      <h2 className="browse-restaurants-card__title alt">
        View all your favourite lunch spots and more
      </h2>
      <Link className="caption-two" to="/eat-out">
        Browse list
      </Link>
    </div>
  );
};
