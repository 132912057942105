import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Comment } from "components/Comment";
import "./comments-list.scss";

export const CommentsList = ({ commentsList, getFormattedDate }) => {
  const [recentTwoComments, setRecentTwoComments] = useState(
    commentsList.slice(-2)
  );
  const [isSeeMoreClicked, setIsSeeMoreClicked] = useState(false);
  const [renderedComments, setRenderedComments] = useState(recentTwoComments);

  useEffect(() => {
    setRecentTwoComments(commentsList.slice(-2));
  }, [commentsList]);

  useEffect(() => {
    if (isSeeMoreClicked) {
      setRenderedComments(commentsList);
    } else {
      setRenderedComments(recentTwoComments);
    }
  }, [isSeeMoreClicked, commentsList, recentTwoComments]);

  const handleSeeMoreComments = () => {
    setIsSeeMoreClicked(!isSeeMoreClicked);
  };

  return (
    <div className="comments-list">
      {commentsList.length > 2 && (
        <button
          className="comments-list__see-more-btn"
          type="button"
          onClick={handleSeeMoreComments}
        >
          {!isSeeMoreClicked ? "View more comments" : "View less comments"}
        </button>
      )}
      <ul className="comments-list__content">
        {renderedComments.map((commentData, i) => {
          return (
            <Comment
              key={i}
              commentData={commentData}
              getFormattedDate={getFormattedDate}
            />
          );
        })}
      </ul>
    </div>
  );
};

CommentsList.propTypes = {
  commentsList: PropTypes.array,
  getFormattedDate: PropTypes.func,
};
