import { useMemo } from "react";

export const useSortBySimilarity = (criteria, data) => {
  return useMemo(() => {
    let variants = [];
    let restVariants = [];

    // criteria restaurant combinations of 2 items
    for (let i = 0; i < criteria.categories.length; i++) {
      for (let j = i + 1; j < criteria.categories.length; j++) {
        variants.push([criteria.categories[i], criteria.categories[j]]);
      }
    }

    // remaining restaurant categories combinations of 2 items
    for (let z = 0; z < data.length; z++) {
      // remove the criteria array item from further evaluation
      if (data[z].id === criteria.id) {
        data.splice(z, 1);
        break;
      }
      data[z].rate = 0;
      restVariants[z] = { id: data[z].id, name: data[z].name, variants: [] };
      for (let i = 0; i < data[z].categories.length; i++) {
        // account for at least 1 matching category
        for (const category of criteria.categories) {
          if (data[z].categories.includes(category)) {
            data[z].rate = data[z].rate + 3;
          }
        }
        // create pairs array
        for (let j = i + 1; j < data[z].categories.length; j++) {
          restVariants[z].variants.push([
            data[z].categories[i],
            data[z].categories[j],
          ]);
        }
      }
    }

    // auxilliary array for rating
    let ratedAuxArray = [...data];

    // find if criteria categories pairs match restaurants categories pairs
    for (let i = 0; i < variants.length; i++) {
      for (let j = 0; j < restVariants.length; j++) {
        for (let z = 0; z < restVariants[j].variants.length; z++) {
          // find the current object in original array
          const item = ratedAuxArray.find((item) => {
            return item.id === restVariants[j].id;
          });
          // if the category pairs match, increase rate for the matching restaurant
          if (
            JSON.stringify(variants[i]) ===
            JSON.stringify(restVariants[j].variants[z])
          ) {
            item.rate = +item.rate + 33;
          }
        }
      }
    }

    // sort according to rate and remove entries with rate 0
    const ratedArray = ratedAuxArray
      .sort((item1, item2) => item2.rate - item1.rate)
      .filter((item) => +item.rate > 0);

    return ratedArray;
  }, [criteria, data]);
};
