import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UserPhoto } from "../UserPhoto";

import "./comment-input.scss";

import { putCursorAtEnd } from "utils/putCursorAtEnd";

export const CommentInput = ({
  handleClick,
  hanldeUserInput,
  inputValue,
  imagePath,
  ariaExpanded,
}) => {
  const [textAreaHeight, setTextAreaHeight] = useState(2.4);
  // handle key presses
  useEffect(() => {
    // handle keypress: submit (Enter) and new line (Ctrl-Enter)
    const keyDownHandler = (event) => {
      if (event.code === "Enter") {
        event.preventDefault();
        if (event.ctrlKey) {
          event.target.value += "\n";
        } else {
          handleClick();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [handleClick]);

  const handleHeight = (e) => {
    setTextAreaHeight((e.target.scrollHeight - 10) / 10);
  };

  return (
    <div className="comment-input" aria-expanded={ariaExpanded}>
      <div className="comment-input__image">
        <UserPhoto imagePath={imagePath} />
      </div>
      <textarea
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onFocus={putCursorAtEnd}
        className="comment-input__textarea"
        placeholder="Leave a comment..."
        maxLength="350"
        aria-label="comment"
        onChange={(e) => hanldeUserInput(e)}
        value={inputValue}
        onKeyUp={handleHeight}
        style={{ height: `${textAreaHeight}rem` }}
      />
      <button
        className="comment-input__submit caption-two"
        type="submit"
        onClick={handleClick}
      >
        Post
      </button>
    </div>
  );
};

CommentInput.propTypes = {
  handleClick: PropTypes.func,
  hanldeUserInput: PropTypes.func,
  inputValue: PropTypes.string,
  imagePath: PropTypes.string,
  ariaExpanded: PropTypes.bool,
};
