import React from "react";
import PropTypes from "prop-types";

import "./restaurant-information.scss";

import { ReactComponent as InformationClock } from "../../assets/restaurant-information/clock.svg";
import { ReactComponent as InformationGlobe } from "../../assets/restaurant-information/globe.svg";
import { ReactComponent as InformationMapPin } from "../../assets/restaurant-information/map-pin.svg";
import { ReactComponent as InformationPhone } from "../../assets/restaurant-information/phone.svg";

export const RestaurantInformation = ({ restaurant }) => {
  const {
    location: { address, coordinates },
    phone,
    website,
    openingHours,
  } = restaurant;

  const mapLink = `http://www.google.com/maps/place/${coordinates.lat},${coordinates.lng}`;
  const informationArray = [
    {
      title: "Address",
      information: address,
      icon: <InformationMapPin />,
      link: mapLink,
    },
    {
      title: "Website",
      information: website.replace(/h\S+\/\//g, "").replace(/www./g, ""),
      link: website,
      icon: <InformationGlobe />,
    },
    {
      title: "Phone Number",
      information: phone,
      link: phone,
      icon: <InformationPhone />,
    },
    {
      title: "Work hours",
      information: `${openingHours[0].days} ${openingHours[0].hours}`,
      additionalInformation: openingHours[1]
        ? `${openingHours[1].days} ${openingHours[1].hours}`
        : null,
      icon: <InformationClock />,
    },
  ];

  return (
    <section className="restaurant-information">
      <h2 className="restaurant-information__title alt">Information</h2>
      <div className="restaurant-information__container">
        {informationArray.map((infoCard, index) => (
          <div key={index} className="restaurant-information__item">
            {infoCard.icon}
            <div className="restaurant-information__data">
              <h3 className="alt">{infoCard.title}</h3>
              {infoCard.link ? (
                infoCard.link === website || infoCard.link === mapLink ? (
                  <a
                    href={infoCard.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {infoCard.information}
                  </a>
                ) : (
                  <a href={`tel:${infoCard.phone}`}>{infoCard.information}</a>
                )
              ) : (
                <p>{infoCard.information}</p>
              )}
              {infoCard.additionalInformation && (
                <p>{infoCard.additionalInformation}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

RestaurantInformation.propTypes = {
  restaurant: PropTypes.shape({
    openingHours: PropTypes.arrayOf(
      PropTypes.exact({
        days: PropTypes.string.isRequired,
        hours: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    location: PropTypes.shape({
      address: PropTypes.string.isRequired,
      coordinates: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    phone: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
  }),
};
