import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "./slide.scss";

export const Slide = ({ slides, slideWidth }) => {
  return (
    <>
      {slides.map((slide, index) => {
        return (
          <div
            style={{
              width: `${slideWidth}%`,
            }}
            className="slide"
            key={index}
          >
            <div className="slide__photo-wrapper">
              <img
                src={`${slide.image}`}
                alt="Featured restaurant"
                className="slide__photo"
              />
            </div>
            <div className="slide__content">
              <div className="tiny slide__slogan">{slide.slogan}</div>
              <h2 className="slide__title">{slide.name}</h2>
              <p className="slide__info">{slide.description}</p>
              <Link
                className="slide__link"
                to={`restaurants/${slide.id}`}
                state={{ name: slide.name }}
              >
                Learn more
              </Link>
            </div>
          </div>
        );
      })}
    </>
  );
};

Slide.propTypes = {
  slides: PropTypes.array,
  slideWidth: PropTypes.number,
};
