import React from "react";
import PropTypes from "prop-types";

import "./user-profile-menu.scss";

import { ReactComponent as Gear } from "../../assets/icons/gear.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { Link } from "react-router-dom";

export const UserProfileMenu = ({ onClick }) => {
  return (
    <ul className="user-profile-menu">
      <li className="user-profile-menu__item">
        <Link to="/">
          <Gear />
          <span>Settings</span>
        </Link>
      </li>
      <li className="user-profile-menu__item">
        <Link to="/login" onClick={onClick}>
          <Logout />
          <span>Logout</span>
        </Link>
      </li>
    </ul>
  );
};

UserProfileMenu.propTypes = {
  onClick: PropTypes.func,
};
