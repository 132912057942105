import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import "./rating-card.scss";

import { averageRating } from "utils/averageRating";
import { ReactComponent as StarIconColored } from "../../assets/icons/icon-star-colored.svg";
import { ReactComponent as StarIconMonochrome } from "../../assets/icons/icon-star-monochrome.svg";

import { UserData } from "context/UserData";

export const RatingCard = ({ reviews }) => {
  const userData = useContext(UserData);

  const [clickRating, setClickRating] = useState();
  const [hoverRating, setHoverRating] = useState();
  const [usersRating, setUsersRating] = useState(reviews);
  const stars = [1, 2, 3, 4, 5];

  const setRating = (i) => {
    let updatedUserRatings;
    let userRating = {
      userName: userData.data.userName,
      id: userData.data.id,
      comment: "",
      rating: i + 1,
    };

    if (clickRating !== i && !clickRating && clickRating !== 0) {
      setClickRating(i);
      updatedUserRatings = [...usersRating, userRating];
      setUsersRating(updatedUserRatings);
      updatedUserRatings = undefined;
    } else if (clickRating !== i && clickRating >= 0) {
      setClickRating(i);
      updatedUserRatings = [...usersRating];
      updatedUserRatings.splice(usersRating.length - 1, 1, userRating);
      setUsersRating(updatedUserRatings);
      updatedUserRatings = undefined;
    } else {
      setClickRating();
      updatedUserRatings = [...usersRating];
      updatedUserRatings.splice(updatedUserRatings.length - 1);
      setUsersRating(updatedUserRatings);
      updatedUserRatings = undefined;
    }
  };

  return (
    !userData.isLoading && (
      <div className="rating-card">
        {stars.map((star, i) => (
          <button
            className="rating-card__icon"
            key={i}
            onMouseEnter={() => setHoverRating(i)}
            onMouseLeave={() => setHoverRating()}
            onClick={() => setRating(i)}
            aria-label={`Rate restaurant ${i + 1} star${i > 0 ? "s" : ""}`}
          >
            {clickRating >= i || hoverRating >= i ? (
              <StarIconColored />
            ) : (
              <StarIconMonochrome />
            )}
          </button>
        ))}
        <span className="rating-card__rate caption">
          {usersRating && usersRating.length !== 0
            ? averageRating(usersRating).toFixed(1)
            : `be first to rate!`}
        </span>
      </div>
    )
  );
};

RatingCard.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      userName: PropTypes.string,
      id: PropTypes.string,
      comment: PropTypes.string,
      rating: PropTypes.number,
    })
  ),
};
