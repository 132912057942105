import React from "react";
import PropTypes from "prop-types";

import "./user-photo.scss";

export const UserPhoto = ({ imagePath }) => {
  return <img className="user-photo" src={imagePath} alt="user" />;
};

UserPhoto.propTypes = {
  imagePath: PropTypes.string.isRequired,
};
