import React from "react";
import { Route, Routes } from "react-router";

import {
  Dashboard,
  EatOut,
  Category,
  Reservation,
  Reservations,
  Restaurant,
  NotFound404,
} from "pages";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="reservations" element={<Reservations />}></Route>
      <Route path="reservations/:name" element={<Reservation />} />
      <Route path="eat-out" element={<EatOut />} />
      <Route path="eat-out/:category" element={<Category />} />
      <Route path="eat-out/restaurants/:id" element={<Restaurant />} />
      <Route
        path="eat-out/:category/restaurants/:id"
        element={<Restaurant />}
      />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};
