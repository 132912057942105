import React from "react";
import PropTypes from "prop-types";
import { Card } from "components/Card";
import { ReactComponent as IconCategoryBurger } from "../../assets/icons/categories/icon-category-burger.svg";
import { ReactComponent as IconCategorySalads } from "../../assets/icons/categories/icon-category-salads.svg";
import { ReactComponent as IconCategoryGrill } from "../../assets/icons/categories/icon-category-grill.svg";
import { ReactComponent as IconCategoryPizza } from "../../assets/icons/categories/icon-category-pizza.svg";
import { ReactComponent as IconCategoryPancakes } from "../../assets/icons/categories/icon-category-pancakes.svg";
import { ReactComponent as IconCategorySoups } from "../../assets/icons/categories/icon-category-soups.svg";
import { ReactComponent as IconCategorySweets } from "../../assets/icons/categories/icon-category-sweets.svg";
import { ReactComponent as IconCategoryBreakfast } from "../../assets/icons/categories/icon-category-breakfast.svg";
import { ReactComponent as IconCategoryRamen } from "../../assets/icons/categories/icon-category-ramen.svg";
import { ReactComponent as IconCategorySandwitch } from "../../assets/icons/categories/icon-category-sandwich.svg";
import { ReactComponent as IconCategoryKebab } from "../../assets/icons/categories/icon-category-kebab.svg";
import { ReactComponent as IconCategorySushi } from "../../assets/icons/categories/icon-category-sushi.svg";
import { ReactComponent as IconCategoryHotDogs } from "../../assets/icons/categories/icon-category-hot-dogs.svg";
import { ReactComponent as IconCategoryDonuts } from "../../assets/icons/categories/icon-category-donuts.svg";
import "./categories.scss";

export const Categories = ({ restaurants }) => {
  const icons = {
    burger: <IconCategoryBurger />,
    salads: <IconCategorySalads />,
    grill: <IconCategoryGrill />,
    pizza: <IconCategoryPizza />,
    pancakes: <IconCategoryPancakes />,
    soups: <IconCategorySoups />,
    sweets: <IconCategorySweets />,
    breakfast: <IconCategoryBreakfast />,
    ramen: <IconCategoryRamen />,
    sandwich: <IconCategorySandwitch />,
    kebab: <IconCategoryKebab />,
    sushi: <IconCategorySushi />,
    "hot dogs": <IconCategoryHotDogs />,
    donuts: <IconCategoryDonuts />,
  };

  const restaurantsInCategoriesCount = restaurants
    .map((restaurant) => restaurant.categories)
    .flat()
    .reduce((categoriesAcc, category) => {
      if (!categoriesAcc[category]) {
        categoriesAcc[category] = 1;
      } else {
        categoriesAcc[category] = categoriesAcc[category] + 1;
      }
      return categoriesAcc;
    }, {});

  const getCategories = () => {
    let categories = [];
    for (const category in restaurantsInCategoriesCount) {
      categories.push({
        category: category,
        restaurantsCount: restaurantsInCategoriesCount[category],
      });
    }
    return categories;
  };

  const categories = getCategories();

  return (
    <section className="categories">
      <h2 className="categories__title alt">Categories</h2>
      <ul className="categories__list">
        {categories.map((cat, index) => {
          return (
            <Card
              key={cat.category}
              title={cat.category}
              count={cat.restaurantsCount}
              text={cat.restaurantsCount > 1 ? "places" : "place"}
              cssModifier="category"
              icon={icons[cat.category.toLowerCase()]}
            />
          );
        })}
      </ul>
    </section>
  );
};

Categories.propTypes = {
  restaurants: PropTypes.array.isRequired,
};
