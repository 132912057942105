import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";

import "./login.scss";

import { FormContainer } from "components/FormContainer";
import { FormField } from "components/FormField/FormField";

import { useAuth } from "hooks/useAuth";
import { fieldTemplate, validateField } from "utils/validation";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  // saving caller-route in case we switch to /register
  const origin = location.state?.from;
  const backToCaller = origin?.pathname || "/";

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formIsValid || !formWasChanged) return; // no API calls before the form is valid

    const messageBody = {
      email: email,
      password: password,
    };

    login(messageBody);

    // after successful login, redirect back to the page that called the login
    // and clear the last item in the  browse history
    navigate(backToCaller, { replace: true });
  };

  const [email, setEmail] = useState({ ...fieldTemplate });
  const [password, setPassword] = useState({ ...fieldTemplate });
  const [formIsValid, setFormIsValid] = useState(false);
  const [formWasChanged, setFormWasChanged] = useState(false);

  const delayedValidateField = useMemo(() => debounce(validateField, 500), []);

  const updateEmail = (event) => {
    setEmail((prev) => {
      return { ...prev, value: event.target.value };
    });
    delayedValidateField(event, setEmail);
  };

  const updatePassword = (event) => {
    setPassword((prev) => {
      return { ...prev, value: event.target.value };
    });
    delayedValidateField(event, setPassword, { simple: true });
  };

  useEffect(() => {
    setFormIsValid(!(email.isInvalid || password.isInvalid));
  }, [email, password]);

  const footerDesc = (
    <span className="login__footer">
      Don&apos;t have an account?{" "}
      <Link to="/register" state={{ from: origin }}>
        Sign&nbsp;up
      </Link>
    </span>
  );

  const handleFormChange = () => setFormWasChanged(() => true);

  return (
    <div className="login">
      <FormContainer
        title="Login"
        titleDesc="Welcome back, please login."
        buttonType="submit"
        buttonIsDisabled={!formIsValid}
        buttonFunc={submitHandler}
        formId="login-form"
        footerDesc={footerDesc}
      >
        <form
          id="login-form"
          onSubmit={submitHandler}
          onChange={handleFormChange}
        >
          <div className="login__field">
            <FormField
              labelName="Email"
              type="email"
              value={email.value}
              onChange={updateEmail}
              onBlur={(event) => validateField(event, setEmail)}
              isInvalid={email.isInvalid}
              errorMessage={email.errorMessage}
            />
          </div>
          <div className="login__field">
            <FormField
              labelName="Password"
              type="password"
              value={password.value}
              onChange={updatePassword}
              onBlur={(event) =>
                validateField(event, setPassword, { simple: true })
              }
              isInvalid={password.isInvalid}
              errorMessage={password.errorMessage}
            />
          </div>
        </form>
      </FormContainer>
    </div>
  );
};

export default Login;

Login.propTypes = {
  origin: PropTypes.any,
};
