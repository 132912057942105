import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./post-card-header.scss";

import { postAgeCalculator } from "../../utils/postAgeCalculator.js";
import { UserPhoto } from "components/UserPhoto/UserPhoto";

export const PostCardHeader = ({ name, imgLink, location, date }) => {
  const [whenPostedOrBirthdayDate, setWhenPostedOrBirthdayDate] = useState("");
  const convertedDate = postAgeCalculator(date);

  useEffect(() => {
    setWhenPostedOrBirthdayDate(convertedDate);
  }, [convertedDate]);

  return (
    <section className="card-header">
      <div className="card-header__inner">
        <div className="card-header__avatar">
          <UserPhoto imagePath={imgLink} />
        </div>
        <div className="card-header__content">
          <span className="card-header__name">
            <b>{name}</b>
          </span>
          <div className="card-header__metadata">
            <span className="card-header__location">{location}</span>
            <span className="card-header__time">
              {whenPostedOrBirthdayDate}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
PostCardHeader.propTypes = {
  name: PropTypes.string.isRequired,
  imgLink: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
