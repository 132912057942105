import "./slider-controls.scss";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ArrowLeft } from "../../assets/slider-vectors/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/slider-vectors/arrow-right.svg";

export const SliderControls = ({
  direction,
  nextSlide,
  prevSlide,
  isArrowDisabled,
}) => {
  return direction === "next" ? (
    <button
      className="controls__btn controls__btn--next"
      onClick={!isArrowDisabled ? nextSlide : null}
      aria-label="Next restaurants"
    >
      <ArrowRight />
    </button>
  ) : (
    <button
      className="controls__btn controls__btn--prev"
      onClick={!isArrowDisabled ? prevSlide : null}
      aria-label="Previous restaurants"
    >
      <ArrowLeft />
    </button>
  );
};

SliderControls.propTypes = {
  direction: PropTypes.string,
  nextSlide: PropTypes.func,
  prevSlide: PropTypes.func,
  isArrowDisabled: PropTypes.bool,
};
