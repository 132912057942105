import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { ErrorMessage } from "../components/ErrorMessage";
import { RestaurantCard } from "components/RestaurantCard/RestaurantCard";
import "./category.scss";

const Category = () => {
  const [restaurants, setRestaurants] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { category } = useParams(); //get category
  const filterParam = (
    category.slice(0, 1).toLocaleUpperCase() + category.slice(1)
  ).replace("-", " ");

  let categoryContent = "";
  let arrayLength = "";

  useEffect(() => {
    // get Restaurants data
    fetch(
      `http://frontendsourceryweb.s3-website.eu-central-1.amazonaws.com/restaurants.json`
    )
      .then((response) => response.json())
      .then((responseData) => setRestaurants(responseData.restaurants))
      .catch((error) => {
        setErrorMessage(error.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    categoryContent = <h2 className="alt">Restaurants are loading...</h2>;
  } else if (!restaurants) {
    categoryContent = <ErrorMessage errorMessage={errorMessage} />;
  } else {
    const restaurantsInCategory = restaurants.filter((restaurant) => {
      return restaurant.categories.includes(filterParam);
    });

    categoryContent = (
      <>
        {restaurantsInCategory.map((restaurant, i) => (
          <RestaurantCard
            key={i}
            id={restaurant.id}
            openingHours={restaurant.openingHours}
            categories={restaurant.categories}
            name={restaurant.name}
            checkIns={restaurant.checkIns}
            reviews={restaurant.reviews}
            image={restaurant.image}
            website={restaurant.website}
            address={restaurant.location.address}
            coordinates={restaurant.location.coordinates}
            description={restaurant.description}
            expand={true}
          />
        ))}
      </>
    );
    const length = restaurantsInCategory.length;
    arrayLength = (length === 1 && "1") || (length === 2 && "2") || "autofit";
  }
  return (
    <div className="category">
      <h1 className="category__header">
        The best places for <span className="category__name">{category}</span>!
      </h1>
      <section className={`category__grid category__grid--${arrayLength}`}>
        {categoryContent}
      </section>
    </div>
  );
};

export default Category;
